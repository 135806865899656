import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import {
  Button,
  Col,
  Input,
  InputGroup,
  InputGroupText,
  Label,
  Row,
} from "reactstrap";
import { useTranslation, withTranslation } from "react-i18next";
import { addFxWithdraw } from "../../../apis/withdraw";
import calculateFee from "../../../helpers/calculateTranFee";
import CardWrapper from "../../Common/CardWrapper";
import CustomModal from "../../Common/CustomModal";
import AddBankAccountModal from "../../BankAccounts/AddBankAccountModal";
import { AvField, AvForm } from "availity-reactstrap-validation";
import AvFieldSelecvt from "components/Common/AvFieldSelect";
import { getAccountsStart } from "store/actions";
import { withdrawalConfig } from "./withdrawalConfig";
import { validateFile } from "helpers/validations/file";
import AsiaBanks from "components/Deposit/Methods/AsiaBanks";

function Mt5Withdraw({ isOpen, toggleOpen, ...props }) { 
  const [baseCurrency, setBaseCurrency] = useState("USD");
  const dispatch = useDispatch();
  const tranFeeGroupDetails = useSelector((state) => state.Profile?.clientData?.transactionFeeId);
  const { accounts } = useSelector(state=> state.forex.accounts);
  const { clientData } = useSelector(state=>state.Profile);
  // eslint-disable-next-line no-unused-vars
  const [loading, setLoading] = useState(false);
  const [activeStep, setActiveStep] = useState(0);
  // eslint-disable-next-line no-unused-vars
  const [selectedWallet, setSelectedWallet] = useState("USD");
  const [amount, setAmount] = useState("");
  const [gateway, setGateway] = useState(""); 
  const [transactionFee, setTransactionFee] = useState("");
  const [result, setResult] = useState("");
  const [selectGatwayError, setSelectGatwayError] = useState(false);
  const [amountError, setAmountError] = useState(false);
  const [selectedPaymentMethod, setSelectedPaymentMethod] = useState();
  // used to check if amount is less than 15 then raise an error
  const [amountValidation, setAmountValidation] = useState(false);
  // eslint-disable-next-line no-unused-vars
  const [selectWalletError, setSelectWalletError] = useState(false);
  const [hoveredPaymentMethod, setHoveredPaymentMethod] = useState();
  const [addBankModal, setAddBankModal] = useState(false);
  const [bankAccount, setBankAccount] = useState({});
  const [isFirstStepValid, setIsFirstStepValid] = useState(false);
  const bankAccounts = useSelector(
    (state) => state.crypto.bankAccounts?.bankAccounts?.docs
  );

  const [methodConfig, setMethodConfig] = useState({});
  const { t } = useTranslation();
  useEffect(() => {
    if (isOpen) {
      setActiveStep(0);
      // setSelectedWallet(defaultFiatWallet?.value);
      setGateway("");
      setAmount("");  
      setIsFirstStepValid(false);
    }
  }, [isOpen]);

  useEffect(() => {
    dispatch(getAccountsStart({
      limit: 100
    }));
  }, []);

  function toggleTab(tab) {
    if (activeStep !== tab) {
      setActiveStep(tab);
    }
  }
  useEffect(() => {
    setTransactionFee(tranFeeGroupDetails ? calculateFee(tranFeeGroupDetails, amount) : 0);
  }, [tranFeeGroupDetails, amount]);

  const handleSubmit = async (e, v) => {
    console.log("v: ", v);
    setLoading(true);
    let payload = {
      gateway,
      amount,
      email: methodConfig?.email,
      tradingAccountId: v.toAccount,
      note: "note",
    };

    if (gateway === "PESKA") {
      payload = {
        ...payload,
        gateway: "PESKA",
        currency: "USD",  // MT5 accounts are always in USD
      };
    } else if (gateway === "WIRE_TRANSFER" || gateway === "LOCAL_WIRE_TRANSFER") {
      payload = {
        ...payload,
        bankAccountId: bankAccount._id
      };
    } else {
      payload = {
        ...payload,
        payload: JSON.stringify(methodConfig)
      };
    }

    if (methodConfig?.file) {
      const formData = new FormData();
      Object.keys(payload).forEach(key => {
        formData.append(key, typeof payload[key] === "string" ? payload[key] : JSON.stringify(payload[key]));
      });
      formData.append("receipt", methodConfig.file);
      payload = formData;
    }

    addFxWithdraw(payload).then((response) => {
      setLoading(false);
      console.log("RES: ", response);
      setResult(response);
      toggleTab(2);
    }).catch((e) => {
      setLoading(false);
      console.log("ERR: ", e);
      setResult(e);
      toggleTab(2);
    }).finally(() => {
      setLoading(false);
    });

  };

  const validateStep1 = () => {
    if (selectedWallet === "") {
      setSelectWalletError(true);
      setTimeout(() => {
        setSelectWalletError(false);
      }, 2000);
    } 
    if (gateway === "") {
      setSelectGatwayError(true);
      setTimeout(() => {
        setSelectGatwayError(false);
      }, 2000);
    }
    if (gateway != "") {
      toggleTab(1);
    }
  };
  const validateStep2 = (e, v) => {
    if (amount == "") {
      setAmountError(true);
      setTimeout(() => {
        setAmountError(false);
      }, 2000);
    } else {
      handleSubmit(e, v);
    }
  };


  const amountValidationHanlder = (e) => {
    e.target.value < 40 ? setAmountValidation(true) : setAmountValidation(false);
  };

  const isFirstSubmitValid = () => {
    if (gateway === "") {
      return setIsFirstStepValid(false);
    } else if ((gateway === "WIRE_TRANSFER" || gateway === "LOCAL_WIRE_TRANSFER")) {
      if (Object.keys(bankAccount).length === 0) {
        return setIsFirstStepValid(false);
      } else {
        return setIsFirstStepValid(true);
      }
    } else if (gateway === "CRYPTO") {
      if (methodConfig?.network && methodConfig?.coin && methodConfig?.address !== "") {
        return setIsFirstStepValid(true);
      } else {
        return setIsFirstStepValid(false);
      }
    } else if (gateway === "صناديق-الاعتماد") {
      if (methodConfig.type === "حواله") {
        if (methodConfig.file && (methodConfig.locationOfHawala !== "" && methodConfig.locationOfHawala !== null && methodConfig.locationOfHawala !== undefined)) {
          return setIsFirstStepValid(true);
        } else {
          return setIsFirstStepValid(false);
        }
      }
      if (methodConfig.type === "اعتماد") {
        if (methodConfig.nameOfBox !== "" && methodConfig.numberOfBox !== "" && methodConfig.nameOfBox !== undefined && methodConfig.numberOfBox !== undefined) {
          return setIsFirstStepValid(true);
        } else {
          return setIsFirstStepValid(false);
        }
      }
    } else if (gateway === "PESKA") {
      const emailValid = /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(methodConfig?.email);
      const isValid =  !!methodConfig?.email && emailValid;
      if (isValid) {  
        return setIsFirstStepValid(true);
      }
    }
    return setIsFirstStepValid(false);
  };

  useEffect(() => {
    isFirstSubmitValid();
  }, [gateway, bankAccount, bankAccounts, methodConfig]);

  const steps = [
    {
      header: t("Select Method"),
      content: (
        <>
          <div className="my-4">
            <AvForm
              onValidSubmit={validateStep1}
            >
              <div className="mb-4">
                <h6 className="mb-3">
                  {props.t("Select Payment Method")}
                  {selectGatwayError && (
                    <p className="small text-danger ">
                      {props.t("Please Select Payment Method")}
                    </p>
                  )}
                </h6>
                <Row className="justify-content-center payment-methods">
                  {
                    withdrawalConfig
                      .filter(method => method.allowed.mt5)
                      .filter((method) => (!method.showForCountries || (method.showForCountries && method.showForCountries.includes(clientData.country))))
                      .map((method, index) => (
                        <Col xs={4} lg={2} className="my-2" key={index}>
                          <button
                            type="button"
                            onClick={() => {
                              setGateway(method.gateway);
                              setSelectedPaymentMethod(method.gateway);
                              setMethodConfig(method);
                            }}
                            onMouseEnter={() => {setHoveredPaymentMethod(method.gateway)}}
                            onMouseLeave={() => {setHoveredPaymentMethod()}}
                            className={`btn btn-${selectedPaymentMethod === method.gateway ? "success" : hoveredPaymentMethod === method.gateway ? "default" : "light"} waves-effect waves-light w-sm py-4`}
                            style={{
                              maxWidth: "80px",
                              maxHeight: "80px",
                            }}
                          >
                            <img
                              src={method.image}
                              // width="100%"
                              // height="100%"
                              alt=""
                            ></img>
                          </button>
                        </Col>
                      ))
                  }
                  {/* <Col xs={4} lg={2} className="my-2">
                    <button
                      type="button"
                      onClick={() => {
                        setGateway("VISA");
                        setSelectedPaymentMethod("VISA");
                      }}
                      onMouseEnter={() => {setHoveredPaymentMethod("VISA")}}
                      onMouseLeave={() => {setHoveredPaymentMethod()}}
                      className={`btn btn-${selectedPaymentMethod === "VISA" ? "success" : hoveredPaymentMethod === "VISA" ? "default" : "light"} waves-effect waves-light w-sm py-4`}
                    >
                      <img
                        src="/img/payment-method/visa.png"
                        width="100%"
                        height="100%"
                        alt=""
                      ></img>
                    </button>
                  </Col>
                  <Col xs={4} lg={2} className="my-2">
                    <button
                      type="button"
                      onClick={() => {
                        setGateway("MASTERCARD");
                        setSelectedPaymentMethod("MASTERCARD");
                      }}
                      onMouseEnter={() => {setHoveredPaymentMethod("MASTERCARD")}}
                      onMouseLeave={() => {setHoveredPaymentMethod()}}
                      className={`btn btn-${selectedPaymentMethod === "MASTERCARD" ? "success" : hoveredPaymentMethod === "MASTERCARD" ? "default" : "light"} waves-effect waves-light w-sm py-4`}
                    >
                      <img
                        src="/img/payment-method/mastercard-1.png"
                        width="100%"
                        height="100%"
                        alt=""
                      ></img>
                    </button>
                  </Col>
                  <Col xs={4} lg={2} className="my-2">
                    <button
                      type="button"
                      onClick={() => {
                        setGateway("NETELLER");
                        setSelectedPaymentMethod("NETELLER");
                      }}
                      onMouseEnter={() => {setHoveredPaymentMethod("NETELLER")}}
                      onMouseLeave={() => {setHoveredPaymentMethod()}}
                      className={`btn btn-${selectedPaymentMethod === "NETELLER" ? "success" : hoveredPaymentMethod === "NETELLER" ? "default" : "light"} waves-effect waves-light w-sm py-4`}
                    >
                      <img
                        src="/img/payment-method/neteller.png"
                        width="100%"
                        height="100%"
                        alt=""
                      ></img>
                    </button>
                  </Col>
                  <Col xs={4} lg={2} className="my-2">
                    <button
                      type="button"
                      onClick={() => {
                        setGateway("SKRILL");
                        setSelectedPaymentMethod("SKRILL");
                      }}
                      onMouseEnter={() => {setHoveredPaymentMethod("SKRILL")}}
                      onMouseLeave={() => {setHoveredPaymentMethod()}}
                      className={`btn btn-${selectedPaymentMethod === "SKRILL" ? "success" : hoveredPaymentMethod === "SKRILL" ? "default" : "light"} waves-effect waves-light w-sm py-4`}
                    >
                      <img
                        src="/img/payment-method/skrill.png"
                        width="100%"
                        height="100%"
                        alt=""
                      ></img>
                    </button>
                  </Col> */}
                </Row>
              </div>
              {gateway == "WIRE_TRANSFER" && (
                <div className="mb-3">
                  <Label>{props.t("Select Bank Account")}</Label>
                  <AvFieldSelecvt
                    name="bankAccount"
                    className="form-select"
                    onChange={(e) => {
                      setBankAccount(e);
                      setMethodConfig({
                        ...methodConfig,
                        bankAccount: e,
                      });
                    }}
                    required
                    placeholder="Select Bank Account"
                    options={bankAccounts?.map((bankAccount) => {
                      return {
                        label:`${bankAccount.bankName}`,
                        value: bankAccount
                      };
                    })}
                  >
                  </AvFieldSelecvt>
                  <InputGroup className="mt-2">
                    <InputGroupText className="custom-input-group-text">
                      <Link
                        to="#"
                        onClick={() => {
                          setAddBankModal(true);
                        }}
                      >
                        {props.t("Add New")}
                      </Link>
                    </InputGroupText>
                  </InputGroup>
                </div>
              )}
              {gateway === "ASIA_BANKS" && <AsiaBanks t={t} setIsFirstStepValid={setIsFirstStepValid}
                setBaseCurrency={setBaseCurrency}/>}
              {gateway === "CRYPTO" && (
                <>
                  <div className="mb-3">
                    <Label>{props.t("Select Network")}</Label>
                    <AvFieldSelecvt
                      name="cryptoNetwork"
                      className="form-select"
                      onChange={(e) => {
                        setMethodConfig({
                          ...methodConfig,
                          network: e,
                        });
                        // setCryptoCurrency(e);
                      }}
                      options={methodConfig?.config.methods.map((method) => {
                        return {
                          label: method.network,
                          value: method,
                        };
                      })}
                    />
                  </div>
                  {
                    methodConfig?.network && (
                      <>
                        <div className="mb-3">
                          <Label>{props.t("Select Coin")}</Label>
                          <AvFieldSelecvt
                            name="cryptoCoin"
                            className="form-select"
                            onChange={(e) => {
                              setMethodConfig({
                                ...methodConfig,
                                coin: e,
                                address: "",
                              });
                              // setCryptoCurrency(e);
                            }}
                            options={methodConfig?.network?.coins?.map((method) => {
                              return {
                                label: method,
                                value: method,
                              };
                            })}
                          />
                        </div>
                      </>
                    )
                  }
                  {
                    methodConfig?.coin && (
                      <>
                        <div className="mb-3">
                          <Label>{props.t("Enter Address")}</Label>
                          <AvField
                            name="cryptoAddress"
                            className="form-control"
                            placeholder="Enter Address"
                            value={methodConfig?.address}
                            onChange={(e) => {
                              setMethodConfig({
                                ...methodConfig,
                                address: e.target.value,
                              });
                            }}
                          />
                        </div>
                      </>
                    )
                  }
                </>
              )
              }
              {gateway === "صناديق-الاعتماد" && (
                <>
                  <div className="mb-3">
                    <Label>{t("Type")}</Label>
                    <AvFieldSelecvt
                      name="sandoqType"
                      className="form-select"
                      placeholder="Select Type"
                      onChange={(e) => {
                        setMethodConfig({
                          ...methodConfig,
                          type: e,
                        });
                      }}
                      options={methodConfig?.config?.methods?.map((option) => {
                        return {
                          label: option?.title,
                          value: option?.title,
                        };
                      })}
                    />
                  </div>
                  {
                    methodConfig?.type && (
                      <>
                        {methodConfig.type === "حواله" && (
                          <>
                            <div className="mb-3">
                              <Label className="form-label" htmlFor="receipt">
                                {t("Receipt")}
                              </Label>
                              <AvField
                                type="file"
                                name="receipt"
                                className="form-control form-control-md"
                                errorMessage={t("Receipt is required")}
                                onChange={(e) => {
                                  setMethodConfig({
                                    ...methodConfig,
                                    file: e.target.files[0],
                                  });
                                }}
                                validate={{
                                  required: { value: true },
                                  validate: validateFile(["jpg", "jpeg", "png"], 1000000, (methodConfig?.file || {}), {
                                    sizeValidationMessage: t("The file size is too large"),
                                    extensionValidationMessage: t("The file extension is not allowed"),
                                  })
                                }}
                              />
                            </div>
                            <div className="mb-3">
                              <Label>{t("وجه الحواله")}</Label>
                              <AvField
                                name="locationOfHawala"
                                type="text"
                                placeholder="وجه الحواله"
                                onChange={(e) => {
                                  setMethodConfig({
                                    ...methodConfig,
                                    locationOfHawala: e.target.value,
                                  });
                                }}
                              />
                            </div>
                          </>
                        )} 
                        {methodConfig.type === "اعتماد" && (
                          <>
                            <div className="mb-3">
                              <Label>{t("اسم الصندوق")}</Label>
                              <AvField
                                name="nameOfBox"
                                type="text"
                                placeholder="اسم الصندوق"
                                onChange={(e) => {
                                  setMethodConfig({
                                    ...methodConfig,
                                    nameOfBox: e.target.value,
                                  });
                                }}
                              />
                            </div>
                            <div className="mb-3">
                              <Label>{t("رقم الصندوق")}</Label>
                              <AvField
                                name="numberOfBox"
                                type="text"
                                placeholder="رقم الصندوق"
                                onChange={(e) => {
                                  setMethodConfig({
                                    ...methodConfig,
                                    numberOfBox: e.target.value,
                                  });
                                }}
                              />
                            </div>
                          </>
                        )} 
                      </>
                    )
                  }
                </>
              )
              }
              {((gateway === "WIRE_TRANSFER" || gateway === "LOCAL_WIRE_TRANSFER") && bankAccount && Object.keys(bankAccount).length > 0) ?
                (
                  <>
                    <AvForm>
                      <h5 className="mb-4">{props.t("Payment details")}</h5>
                      <div>
                        <Label>{props.t("Beneficiary Name")}</Label>
                        <AvField
                          type="text"
                          name="accountHolderName"
                          value={`${clientData?.firstName} ${clientData?.lastName}`}
                          validate={{ required:true }}
                          disabled={true}
                        >
                        </AvField>
                      </div>
                      <div>
                        <Label>{props.t("Bank Name")}</Label>
                        <AvField
                          type="text"
                          name="bankName"
                          value={bankAccount.bankName}
                          validate={{ required:true }}
                          disabled={true}
                        >
                        </AvField>
                      </div>
                      <div>
                        <Label>{props.t("Account Number")}</Label>
                        <AvField
                          type="text"
                          name="accountNumber"
                          value={bankAccount.accountNumber}
                          validate={{ required:true }}
                          disabled={true}
                        >
                        </AvField>
                      </div>
                      <div>
                        <Label>{props.t("Address")}</Label>
                        <AvField
                          type="text"
                          name="address"
                          value={bankAccount.address}
                          validate={{ required:true }}
                          disabled={true}
                        >
                        </AvField>
                      </div>
                      <div>
                        <Label>{props.t("Swift/IFSC Code")}</Label>
                        <AvField
                          type="text"
                          name="swiftCode"
                          value={bankAccount.swiftCode}
                          validate={{ required:true }}
                          disabled={true}
                        >
                        </AvField>
                      </div>
                      <div>
                        <Label>{props.t("Currency")}</Label>
                        <AvField
                          type="text"
                          name="currency"
                          disabled={true}
                          value={bankAccount.currency}
                          validate={{ required:true }}
                        >
                        </AvField>
                      </div>
                      <p>{props.t("Bank Account")}</p>
                      {/* <p className="text-muted">
                        {props.t("You MUST include the Reference Code in your deposit in order to credit your account!")}
                      </p> */}
                    </AvForm>
                  </>  
                )  : gateway == "SKRILL" ||
                gateway == "NETELLER" ||
                gateway == "MASTERCARD" ? (
                    <>
                      <p className="text-muted">{props.t("")}{props.t("Enter card information.")}</p>
                      <Row>
                        <AvForm>
                          <Col xs={12}>
                            <div className="mb-3">
                              <Label
                                htmlFor="example-date-input"
                                className="form-label"
                              >
                                {props.t("Name")}
                              </Label>
                              <Input
                                className="form-control"
                                type="text"
                                id="example-date-input"
                              />
                            </div>
                          </Col>
                          <Col xs={12}>
                            <div className="mb-3">
                              <Label
                                htmlFor="example-date-input"
                                className="form-label"
                              >
                                {props.t("Card Number")}
                              </Label>
                              <Input
                                className="form-control"
                                type="text"
                                id="example-date-input"
                              />
                            </div>
                          </Col>
                          <Col md={6}>
                            <div className="mb-3">
                              <Label
                                htmlFor="example-date-input"
                                className="form-label"
                              >
                                {props.t("Expiry date")}
                              </Label>
                              <Input
                                className="form-control"
                                type="date"
                                id="example-date-input"
                              />
                            </div>
                          </Col>
                          <Col md={6}>
                            <div className="mb-3">
                              <Label
                                htmlFor="example-date-input"
                                className="form-label"
                              >
                                {props.t("Security Code (CVV)")}
                              </Label>
                              <Input
                                className="form-control"
                                type="text"
                                id="example-date-input"
                              />
                            </div>
                          </Col>
                        </AvForm>
                      </Row>
                    </>
                  ) : (
                    ""
                  )
              }{gateway === "PESKA" && (
                <div className="mb-3">
                  <Label>{t("Email")}</Label>
                  <AvField
                    name="peskaEmail"
                    type="email"
                    placeholder={t("Enter Email")}
                    value={methodConfig.email || ""}
                    onChange={(e) => {
                      setMethodConfig({
                        ...methodConfig,
                        email: e.target.value
                      });
                    }}
                    validate={{
                      required: { 
                        value: true, 
                        errorMessage: t("Email is required") 
                      },
                      email: { 
                        value: true, 
                        errorMessage: t("Invalid email format") 
                      }
                    }}
                  />
                </div>
              )}
              <div className="text-center mt-4">
                <Button
                  type="submit"
                  className="btn btn-success waves-effect waves-light w-lg btn-sm"
                  disabled={!isFirstStepValid}
                >
                  {props.t("Continue")}
                </Button>
              </div>
            </AvForm>
          </div>
        </>
      ),
    },
    {
      header: "Enter Amount",
      content: (
        <>
          {
            <>
              <AvForm
                onValidSubmit={(e, v) => {
                  validateStep2(e, v);
                }}
              >
                <h6 className="mb-3">{props.t("Enter Amount")}</h6>
                <div className="d-flex justify-content-between mb-2">
                  <div>
                    <Label htmlFor="example-date-input" className="form-label">
                      {props.t("Amount")}
                    </Label>
                  </div>
                  <div>
                    {props.t("Transaction requirements")}
                    <i className="fas fa-info-circle ms-2"></i>
                  </div>
                </div>
                <InputGroup>
                  <Input
                    required
                    onChange={(e) => {
                      amountValidationHanlder(e);
                      setAmount(e.target.value);
                    }}
                    className="form-control"
                    type="number"
                    min="0"
                    value={amount}
                    placeholder="Enter 15-128341"
                    onKeyPress={(e) => {
                      if (!/[0-9]/.test(e.key)) {
                        e.preventDefault();
                      }
                    }}
                  />
                  {/* <InputGroupText className="custom-input-group-text p-0 border-0">
                    <div style={{
                      width: "127px",
                    }}
                    >
                      <AvFieldSelect
                        style={{ height:"100%" }}
                        name="wallets"
                        options={[{
                          label:"USD",
                          value:"USD"
                        }]}
                        defaultValue={"USD"}
                        onChange={(e) => {
                          setSelectedWallet(e);
                        }}
                      >
                      </AvFieldSelect>
                    </div>
                  </InputGroupText> */}
                </InputGroup>
                <div className="mt-3">
                  <AvFieldSelecvt
                    options={
                      accounts?.filter(a => !["DEMO", "IB"].includes(a.type))?.filter(a => a.profile ? a.profile?.profileType === 9 : true)?.map(acc=>{
                        return {
                          label:`${acc?.login} ${acc?.accountTypeId?.title} (${acc?.Balance} ${acc?.currency})`,
                          value: acc?._id
                        };
                      })
                    }
                    name="toAccount"
                    type="text"
                    errorMessage={props.t("to account is required")}
                    validate={{ required: { value: true } }}
                    label={props.t("MT5 Account")}
                  >
                  </AvFieldSelecvt>
                </div>
                {amountError && (
                  <p className="small text-danger "> {props.t("Please Select Account")}</p>
                )}
                {amountValidation && (
                  <p className="small text-danger "> {props.t("The minimum value for amount is 40")}</p>
                )}
                <div className="text-center fw-bolder mt-4 received-amount">
                  <span className="fs-5">{selectedWallet?.label}</span>
                  <span className="fs-1">{amount}</span>
                </div>
                {/* <div className="mb-3">
                  <Label className="form-label mb-2">{props.t("Transaction Fee")}</Label>
                  <InputGroup className="">
                    <InputGroupText className=" w-100">
                      {transactionFee}{"   "}{selectedWallet?.label}
                    </InputGroupText>
                  </InputGroup>
                </div> */}
                <div className="my-4 text-center">
                  <Button
                    className="btn btn-primary m-2 btn-sm w-lg"
                    onClick={() => toggleTab(0)}
                  >
                    {props.t("Back")}
                  </Button>
                  <Button
                    className="btn btn-success m-2 btn-sm w-lg"
                    type="submit"
                    disabled={loading || amountValidation}
                  >
                    {props.t("Continue")}
                  </Button>
                </div>
              </AvForm>
            </>
          }
        </>
      ),
    },
    {
      header: "Withdraw status",
      content: (
        <>
          {result.status ? (
            <>
              <div className="text-center  mb-4">
                <h1 className="fs-1 mb-5">
                  {props.t("Yay!")} <i className="bx bx-wink-smile"></i>
                </h1>
                <p>{props.t("Your successfully a withdrawal")}</p>
                <p>
                  <span className="positive">
                    {result?.result?.data?.withdrawal?.gateway === "PESKA" ? result?.result?.data?.withdrawal?.amount?.$numberDecimal : result?.result?.amount?.$numberDecimal}{" "}
                    {result?.result?.data?.withdrawal?.gateway === "PESKA" ? result?.result?.data?.withdrawal?.currency : result?.result?.currency}
                  </span>
                </p>
              </div>
              <CardWrapper className="mb-4">
                <div className="d-flex align-items-center justify-content-between px-5">
                  <div>
                    <div className="text-muted">{props.t("Status")}</div>
                    <div className="positive">{result?.result?.data?.withdrawal?.gateway === "PESKA" ? result?.result?.data?.withdrawal?.status : props.t("Completed")}</div>
                  </div>
                  <div>
                    <div className="text-muted">
                      {props.t("Transaction ID")}
                    </div>
                    <div>{result?.result?.data?.withdrawal?.gateway === "PESKA" ? result?.result?.data?.withdrawal?._id :  result?.result?._id}</div>
                  </div>
                </div>
              </CardWrapper> 
            </>
          ) : (
            <>
              <div className="text-center mb-4">
                <h1 className="fs-1 mb-5">
                  {props.t("Oops!")} <i className="bx bx-error-circle text-danger"></i>
                </h1>
                <p>{props.t("Your Withdraw Request Not Successfully Created")}</p>
                <p>
                  <span className="text-danger">
                    {amount} USD
                  </span>
                </p>
              </div>
              <CardWrapper className="mb-4">
                <div className="d-flex align-items-center justify-content-around px-4">
                  <div>
                    <div className="negative">{result?.message}</div>
                    <div className="text-muted">{props?.t("Status")}</div>
                    <div className="fs-5 fw-bold text-danger">{props?.t("FAILED")}</div>
                  </div>
                  {result?.error && <div>
                    <div className="text-muted">{props?.t("Error Details")}</div>
                    <div className="fs-5 text-danger">{result?.error}</div>
                  </div>}
                </div>
              </CardWrapper>
            </>
          )}
          <div className="text-center">
            <Button
              className="btn btn-danger m-2 btn-sm w-lg"
              onClick={toggleOpen}
            >
              {props.t("Continue")}
            </Button>
          </div>
        </>
      ),
    },
  ];

  return (
    <>
      <CustomModal
        steps={steps}
        isOpen={isOpen}
        toggleOpen={toggleOpen}
        activeStep={activeStep}
        toggleTab={toggleTab}
      ></CustomModal> 
      <AddBankAccountModal
        isOpen={addBankModal}
        toggleOpen={() => {
          setAddBankModal(false);
        }}
      ></AddBankAccountModal>
    </>

  );
}
export default withTranslation()(Mt5Withdraw);