import CardWrapper from "components/Common/CardWrapper";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import {
  Button,
  ButtonGroup,
  CardBody, CardTitle, Spinner
} from "reactstrap";
import FeatherIcon from "feather-icons-react";
import ReactApexChart from "react-apexcharts";
import { getMonthlySummaryAPI } from "apis/forex/ib";
import classNames from "classnames";
import moment from "moment";

const def =  [
  {
    name: "Rebate",
    data: [],
  },
  {
    name: "Commission",
    data: [],
  },
];

export default function MonthlyCommissionGraph() {
  const { t } = useTranslation();
  const [loading, setLoading] = React.useState(false);
  const [data, setData] = React.useState(def);
  const [type, setType] = React.useState("yearly");

  useEffect(() => {
    setLoading(true);
    // call api
    getMonthlySummaryAPI({
      type,
    }).then((res) => {
      if (res.isSuccess) {
        const rebate = res.result?.find((r) => r.id?.toUpperCase() === "REBATE");
        const commission = res.result?.find((r) => r.id?.toUpperCase() === "COMMISSION");
        if (rebate && commission && type !== "weekly") {
          setData([
            {
              name: "Rebate",
              // data: rebate?.data?.sort((a, b) => a.x - b.x),
              // sort data by date
              data: rebate?.data?.sort((a, b) => {
                const dateA = new Date(a.x);
                const dateB = new Date(b.x);
                return dateA - dateB;
              }),
            },
            {
              name: "Commission",
              data: commission?.data?.sort((a, b) => {
                const dateA = new Date(a.x);
                const dateB = new Date(b.x);
                return dateA - dateB;
              }),
            },
          ]);
        } else if (type === "weekly") {
          setData([
            {
              name: "Rebate",
              // data: rebate?.data?.sort((a, b) => a.x - b.x),
              // sort data by date
              data: rebate?.data?.sort((a, b) => {
                const dateA = new Date(a.x);
                const dateB = new Date(b.x);
                return dateA - dateB;
              })?.map((d) => {
                return {
                  ...d,
                  x: `${d.x} - ${moment(d.x).add(6, "days").format("MM-DD")}`
                };
              }),
            },
            {
              name: "Commission",
              data: commission?.data?.sort((a, b) => {
                const dateA = new Date(a.x);
                const dateB = new Date(b.x);
                return dateA - dateB;
              })?.map((d) => {
                return {
                  ...d,
                  x: `${d.x} - ${moment(d.x).add(6, "days").format("MM-DD")}`
                };
              }),
            },
          ]);
        }
      }
    }).catch((err) => {
      console.log(err);
    }).finally(() => {
      setLoading(false);
    });
  }, [type]);

  return (
    <CardWrapper className={"glass-card shadow mt-2"}>
      <CardTitle className="">
        <h5 className="color-primary d-flex align-items-center justify-content-between">
          <div>
            <FeatherIcon 
              className="color-primary mx-1"
              icon="pie-chart"
            />
            {t("Rebate and Commission")}
          </div>
          <div>
            <ButtonGroup>
              <Button 
                className={classNames("btn btn-light border-0 ", {
                  "shadow-lg text-white color-bg-btn": type === "yearly",
                })}
                onClick={() => {
                  setType("yearly");
                }}>{t("Yearly")}</Button>
              <Button 
                className={classNames("btn btn-light border-0 ", {
                  "shadow-lg text-white color-bg-btn": type === "weekly",
                })}
                onClick={() => {
                  setType("weekly");
                }}>{t("Monthly")}</Button>
              <Button 
                className={classNames("btn btn-light border-0 ", {
                  "shadow-lg text-white color-bg-btn": type === "daily",
                })}
                onClick={() => {
                  setType("daily");
                }}>{t("Daily")}</Button>
            </ButtonGroup>
          </div>
        </h5>
      </CardTitle>
      <CardBody className="text-center">
        {
          loading ? <Spinner /> : <>
            <ReactApexChart
              type="bar"
              series={data}
              options={{
                chart: {
                  type: "bar",
                  stacked: true,
                },
                dataLabels: {
                  enabled: false,
                },
                stroke: {
                  width: 2,
                },
                tooltip: {
                  shared: true,
                  intersect: false,
                  y: {
                    formatter: function (y) {
                      if (typeof y !== "undefined") {
                        return y.toFixed(2) + " $";
                      }
                      return y;
                    },
                  },
                }
              }}
              width="100%"
            />
          </>
        }
      </CardBody>
    </CardWrapper>
  );
}
